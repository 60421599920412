<template>
    <ProjectPageTemplate
    style="position: relative; height: 100%; width: 100%;"
  
      :hideHeader="true"
      :title="$t(`${$route.meta.i18n}.title`)"
      :error="!isLoading && latestImages == null"
      :loading="isLoading && hasCapture"
    >
      <template v-slot:content>
        <div style="position: relative; height: 100%; max-height: 100%; width: 100%;  overflow-y: hidden;">

        <div v-if="hasCapture" id="overview" class="dashboard-area pl-2 pr-2">
          <!-- Live view gallery -->
          <!-- {{ $store.getters.project }} -->
          <LiveViewGallery
            v-on:update="updated += 1"
            :autoplay="true"
            :playbackSpeed="playbackSpeed"
            :latest="latestImages"
          />
          <div
            v-show="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)"
          >
            <v-divider class="mr-2 ml-2" style="opacity: 0.5" />
  
            <!-- Dashboard settings area; autoplay & speed of viedos can be adjusted -->
            <v-row class="ma-0 mb-3 ml-4 mr-4 pt-3" align="center" justify="end">
              <v-spacer />
  
              <PlaybackSpeed
                :speed="playbackSpeed"
                v-on:update="playbackSpeed = $event"
              />
            </v-row>
          </div>
        </div>
  
        <div v-else class="pl-3 pr-3 pt-2">
          <v-img
            v-show="!$vuetify.breakpoint.xsOnly"
            width="650"
            class="background-image"
            src="@/assets/helper/alinotec_cameras.webp"
          />
        </div>

        </div>
      </template>
    </ProjectPageTemplate>
  </template>
  
  <script>
  import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';
  import LiveViewGallery from '@/components/tools/project/dashboard/LiveViewGallery.vue';
  import PlaybackSpeed from '@components/video/PlaybackSpeed.vue';
  
  export default {
    name: 'ProjectViewDashboard',
    components: {
      ProjectPageTemplate,
      LiveViewGallery,
      PlaybackSpeed,
    },
  
    data() {
      return {
        playbackSpeed: 0.7,
        isLoading: true,
        hasError: false,
        refreshInterval: null,
        latestMinute: -1,
        updated: false,
      };
    },
  
    created() {
      // remove loading if images exist
      if (this.latestImages != null) this.isLoading = false;
  
      // get user specific settings from local storage:
  
      // 1: playback speed
      if (this.$localStorage.get('playback') != null) {
        this.playbackSpeed = parseFloat(this.$localStorage.get('playback'));
      }
  
      // images have to be refreshed every 5 minutes; to minimize load on the server, last minute is stored, so a minimum of 1 minute is between
      // each request. Additionally it is checked if minutes minus 1 is modulo 5; if so, a new image was captured & page is refreshed; function
      // is called every 20s TODO adapt comment


      this.refreshInterval = setInterval(() => {
        this.refreshLatestData();
      }, 2000);
      this.refreshLatestData();
    },
  
    destroyed() {
      // remove interval to get new image
      if (this.refreshInterval != null) clearInterval(this.refreshInterval);
    },
  
    watch: {
      playbackSpeed() {
        // if variable is changed, new value is added to the local storage
        if (parseInt(this.$localStorage.get('playback')) != this.playbackSpeed) {
          this.$localStorage.set('playback', this.playbackSpeed);
        }
      },
  
      latestImages: {
        handler() {
          if (this.latestImages != null) this.isLoading = false;
        },
        immediate: true,
        deep: true,
      },
    },
  
    methods: {
      refreshLatestData() {
        // sets the documentation seconds to either the documentation interval if present or  5 minutes by default in order not to cause type error
        const secondsDocumentation = this.project.general.documentationInterval != null ? 
                          this.project.general.documentationInterval.days * 86400 +
                          this.project.general.documentationInterval.hours * 3600 +
                          this.project.general.documentationInterval.minutes * 60 +
                          this.project.general.documentationInterval.seconds 
                          : 5;

        if ( this.project.latest.data[0] == null || 
            (Date.now() - new Date([...this.project.latest.data].sort(
              (a, b) => new Date(b.imageTime).getTime() - new Date(a.imageTime).getTime())[0].imageTime).getTime() ) > (secondsDocumentation*1000 + 2000)) {        
          return this.$api.project.latestImages(this.$route.params.projectId);
        }
      },
    },
  
    computed: {
      hasParkControl() {
        return this.$store.getters.project.general.allowParkControl;
      },
      hasCapture() {
        return this.$store.getters.project.general.allowRecordings;
      },
      latestImages() {
        if (this.$store.getters.latest == null) return;
        return this.$store.getters.latest.data;
      },
  
      project() {
        return this.$store.getters.project;
      },
    },
  };
  </script>
  
  <style scoped>
  .dashboard-area {
    height: 100%;
    position: relative;
    background-color: transparent;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden !important;
  }
  
  .background-image {
    position: absolute;
    right: -20px;
    bottom: 0px;
    opacity: 0.2;
    z-index: 2 !important;
  }
  </style>
  