var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gallery-image-container",style:(_vm.loadingImage == true ? 'cursor: default!important' : 'cursor: auto;')},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fullscreen && _vm.playable && !_vm.motionPlaySelector && _vm.loaded),expression:"fullscreen && playable && !motionPlaySelector && loaded"}]},[_c('PlaybackSpeed',{staticClass:"playbackspeed-overlay",attrs:{"speed":_vm.playbackSpeedLocal},on:{"update":function($event){_vm.playbackSpeedLocal = $event}}})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:((_vm.hover || _vm.fullscreen) && !_vm.metaPressed && _vm.dataHandle != null),expression:"(hover || fullscreen) && !metaPressed && dataHandle != null"}],key:_vm.updatedData,staticClass:"ma-0 action-buttons",attrs:{"align":"center"}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataHandle.isLatestImagePublic),expression:"dataHandle.isLatestImagePublic"}],staticClass:"primary pa-1 mr-2",staticStyle:{"border-radius":"50%","opacity":"0.75"},attrs:{"color":"white"}},[_vm._v("mdi-earth")]),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.fullscreen &&
        _vm.playable == true &&
        _vm.loaded == true &&
        _vm.$refs.video != null &&
        _vm.$refs.video.duration != null &&
        _vm.$refs.video.duration != 0 &&
        !(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly)
      ),expression:"\n        fullscreen &&\n        playable == true &&\n        loaded == true &&\n        $refs.video != null &&\n        $refs.video.duration != null &&\n        $refs.video.duration != 0 &&\n        !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)\n      "}],staticClass:"gallery-menu ml-2",style:(!_vm.motionPlaySelector ? 'background-color: rgba(0, 0, 0, .3)' : ''),attrs:{"buttonColor":!_vm.motionPlaySelector ? 'grey lighten-4' : 'black',"icon":"mdi-motion-play"},on:{"click":function($event){_vm.motionPlaySelector = !_vm.motionPlaySelector}}}),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.timelapse != null &&
        _vm.timelapse.downloadUrl != null &&
        !(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly)
      ),expression:"\n        timelapse != null &&\n        timelapse.downloadUrl != null &&\n        !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)\n      "}],staticClass:"gallery-menu ml-2",style:(!_vm.playable ? 'background-color: rgba(0, 0, 0, .3)' : ''),attrs:{"buttonColor":!_vm.playable ? 'grey lighten-4' : 'black',"disabled":_vm.loadingImage,"loading":!_vm.loadingImage && !_vm.loaded && _vm.timelapse != null,"icon":"mdi-timelapse"},on:{"click":function($event){return _vm.timelapseReplay(_vm.dataHandle)}}}),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fullscreen),expression:"!fullscreen"}],staticClass:"gallery-menu ml-2",attrs:{"color":"black","buttonColor":"black","icon":"mdi-link-variant"},on:{"click":function($event){return _vm.$emit('share', _vm.dataHandle)}}}),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly)),expression:"!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)"}],staticClass:"gallery-menu ml-2",attrs:{"color":"black","buttonColor":"black","icon":!_vm.fullscreen ? 'mdi-fullscreen' : 'mdi-fullscreen-exit'},on:{"click":function($event){return _vm.$emit('fullscreen', _vm.index)}}})],1),(
      !(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly) &&
      !_vm.loadingImage &&
      _vm.timelapse != null &&
      _vm.timelapse.downloadUrl != null &&
      !_vm.metaPressed &&
      ((((_vm.hover && _vm.autoplay) || !_vm.autoplay) && !_vm.fullscreen && _vm.playable) ||
        (_vm.playable && _vm.fullscreen))
    )?_c('div',[_c('video',{directives:[{name:"show",rawName:"v-show",value:(
        !(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly) &&
        _vm.timelapse != null &&
        _vm.timelapse.downloadUrl != null &&
        !_vm.metaPressed &&
        ((((_vm.hover && _vm.autoplay) || !_vm.autoplay) && !_vm.fullscreen && _vm.playable) ||
          (_vm.playable && _vm.fullscreen))
      ),expression:"\n        !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) &&\n        timelapse != null &&\n        timelapse.downloadUrl != null &&\n        !metaPressed &&\n        ((((hover && autoplay) || !autoplay) && !fullscreen && playable) ||\n          (playable && fullscreen))\n      "}],ref:"video",staticClass:"video-player",style:(!_vm.loaded ? 'opacity: 0; cursor: progress' : ''),attrs:{"autoplay":!_vm.motionPlaySelector,"muted":"","loop":!_vm.motionPlaySelector,"preload":""},domProps:{"muted":true},on:{"canplaythrough":_vm.loadedVideoData,"timeupdate":_vm.timeUpdateEvent,"loadstart":function($event){_vm.loaded = false}}},[_c('source',{attrs:{"src":_vm.timelapse.downloadUrl,"type":"video/mp4","codecs":"avc1.4d002a, mp4a.40.2"}})])]):_vm._e(),_c('v-overlay',{staticStyle:{"z-index":"10"},attrs:{"color":"white","value":_vm.loadingImage,"absolute":!_vm.fullscreen}},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":"3","size":"30","color":"primary"}})],1),_c('ImageZoom',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.fullscreen && _vm.dataHandle != null && _vm.dataHandle.image.downloadUrl != null
    ),expression:"\n      fullscreen && dataHandle != null && dataHandle.image.downloadUrl != null\n    "}],staticClass:"gallery-image",staticStyle:{"height":"100%","width":"100%"},style:((_vm.metaPressed && !_vm.fullscreen) || !_vm.playable || _vm.timelapse == null
        ? ''
        : 'cursor: default!important;'),attrs:{"disabled":false,"zoomScale":1.5,"hideHint":true,"src":_vm.dataHandle.image.downloadUrl || ''},on:{"load":_vm.loadedImageEvent}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.fullscreen &&
      _vm.dataHandle != null &&
      _vm.dataHandle.image.downloadUrl != null
    ),expression:"\n      !fullscreen &&\n      dataHandle != null &&\n      dataHandle.image.downloadUrl != null\n    "}],staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.dataHandle.image.downloadUrl || ''},on:{"load":_vm.loadedImageEvent}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataHandle == null || _vm.dataHandle.image.downloadUrl == null),expression:"dataHandle == null || dataHandle.image.downloadUrl == null"}],staticClass:"image-error"},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black","size":"15"}},[_vm._v("mdi-alert-circle")]),_c('b',[_vm._v(_vm._s(_vm.$t('tools.project.dashboard.error.image')))])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      !(_vm.metaPressed && _vm.hover) &&
      _vm.dataHandle.presetId != null &&
      _vm.dataHandle.presetId != null &&
      !_vm.motionPlaySelector
    ),expression:"\n      !(metaPressed && hover) &&\n      dataHandle.presetId != null &&\n      dataHandle.presetId != null &&\n      !motionPlaySelector\n    "}],staticClass:"gallery-alt-preset font-weight-bold black--text"},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly)),expression:"!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)"}],staticClass:"font-weight-medium mr-1",staticStyle:{"font-size":"14px","color":"rgba(0, 0, 0, 0.6)","padding-bottom":"2px"}},[_vm._v(_vm._s(_vm.$t('tools.project.dashboard.preset'))+":")]),_c('span',{staticClass:"font-weight-normal"},[_vm._v(_vm._s(_vm.$helper.shortenText(_vm.$helper.formatPresetName(_vm.dataHandle.presetId), 16)))])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      !(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly) &&
      !(_vm.metaPressed && _vm.hover) &&
      _vm.dataHandle.presetId != null &&
      _vm.dataHandle.presetId != null &&
      _vm.hover
    ),expression:"\n      !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) &&\n      !(metaPressed && hover) &&\n      dataHandle.presetId != null &&\n      dataHandle.presetId != null &&\n      hover\n    "}],staticClass:"gallery-alt-date font-weight-bold black--text"},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('span',{staticClass:"font-weight-normal",staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.$helper.formatDate(_vm.dataHandle.imageTime)))])])],1),(_vm.$refs.video != null)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.playable &&
      _vm.loaded &&
      ((_vm.hover && _vm.autoplay) || _vm.playable) &&
      (_vm.hover || _vm.fullscreen) &&
      !_vm.metaPressed &&
      _vm.dataHandle != null
    ),expression:"\n      playable &&\n      loaded &&\n      ((hover && autoplay) || playable) &&\n      (hover || fullscreen) &&\n      !metaPressed &&\n      dataHandle != null\n    "}],staticClass:"white--text",staticStyle:{"position":"absolute","top":"10px","left":"10px","z-index":"10"}},[_c('span',{key:_vm.currentVideoFrame},[_c('div',{staticClass:"pa-1",staticStyle:{"background-color":"rgba(255, 255, 255, 0.8)","width":"32px","height":"32px","border-radius":"19px"}},[_c('v-progress-circular',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"},attrs:{"rotate":"-90","width":"6","size":"21","color":"primary","value":(_vm.currentVideoFrame / _vm.maxVideoFrame) * 100}})],1)])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.fullscreen &&
      _vm.motionPlaySelector &&
      _vm.playable &&
      _vm.$refs.video != null &&
      _vm.$refs.video.duration != null &&
      _vm.$refs.video.duration != 0
    ),expression:"\n      fullscreen &&\n      motionPlaySelector &&\n      playable &&\n      $refs.video != null &&\n      $refs.video.duration != null &&\n      $refs.video.duration != 0\n    "}],staticStyle:{"position":"absolute","top":"10px","left":"50%","transform":"translateX(-50%)","background-color":"rgba(255, 255, 255, 0.9)","z-index":"1000","width":"80%","max-width":"420px","border-radius":"10px"}},[_c('v-row',{staticClass:"ma-0 pl-4 pr-4 pt-1 pb-1",attrs:{"align":"center"}},[_c('span',{staticStyle:{"font-size":"16px"}},[_c('b',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('tools.project.dashboard.fullscreen.timelapse')))]),_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.$t('tools.project.dashboard.fullscreen.frameByFrame')))])]),_c('v-spacer'),(_vm.$refs.video != null)?_c('span',{staticStyle:{"font-size":"14px","color":"rgba(0, 0, 0, 0.5)"}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.videoMotionPosition / 10))]),_vm._v(" / "),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(Math.ceil(_vm.$refs.video.duration / (1 / 30))))])]):_vm._e(),_c('IconButton',{staticClass:"ml-2",style:(_vm.sliderSelector
            ? 'transform: rotate(-180deg); transition: all 200ms linear'
            : 'transition: all 200ms linear'),attrs:{"icon":"mdi-chevron-down"},on:{"click":function($event){_vm.sliderSelector = !_vm.sliderSelector}}})],1),(_vm.$refs.video != null && _vm.$refs.video.duration != null)?_c('v-slider',{directives:[{name:"show",rawName:"v-show",value:(_vm.sliderSelector),expression:"sliderSelector"}],staticClass:"pt-1 pl-6 pr-6 pb-4",attrs:{"dense":"","hide-details":"","track-color":"grey darken-2","color":"primary","thumb-size":2,"step":10,"min":10,"max":Math.ceil(_vm.$refs.video.duration / (1 / 30)) * 10},model:{value:(_vm.videoMotionPosition),callback:function ($$v) {_vm.videoMotionPosition=$$v},expression:"videoMotionPosition"}}):_vm._e()],1),_c('v-fade-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.lastFrameBlink),expression:"lastFrameBlink"}],staticStyle:{"height":"100vh","width":"100vw","background-color":"transparent","z-index":"10000","position":"absolute"}},[_c('div',{staticStyle:{"position":"absolute","top":"47%","left":"50%","transform":"translate(-50%, -50%)","width":"60px","height":"60px","background-color":"white","opacity":"0.5","border-radius":"50%"}},[_c('v-icon',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"},attrs:{"size":"36","color":"black"}},[_vm._v("mdi-close-circle-outline")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }