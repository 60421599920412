<template>
  <div
    class="gallery-image-container"
    :style="
      loadingImage == true ? 'cursor: default!important' : 'cursor: auto;'
    "
  >
    <!-- Playback speed selector additionally displayed in fullscreen mode -->
    <div v-show="fullscreen && playable && !motionPlaySelector && loaded">
      <PlaybackSpeed
        class="playbackspeed-overlay"
        :speed="playbackSpeedLocal"
        v-on:update="playbackSpeedLocal = $event"
      />
    </div>

    <v-row
      :key="updatedData"
      v-show="(hover || fullscreen) && !metaPressed && dataHandle != null"
      align="center"
      class="ma-0 action-buttons"
    >
      <v-icon
        v-show="dataHandle.isLatestImagePublic"
        class="primary pa-1 mr-2"
        style="border-radius: 50%; opacity: 0.75"
        color="white"
        >mdi-earth</v-icon
      >

      <IconButton
        v-show="
          fullscreen &&
          playable == true &&
          loaded == true &&
          $refs.video != null &&
          $refs.video.duration != null &&
          $refs.video.duration != 0 &&
          !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)
        "
        class="gallery-menu ml-2"
        :buttonColor="!motionPlaySelector ? 'grey lighten-4' : 'black'"
        :style="
          !motionPlaySelector ? 'background-color: rgba(0, 0, 0, .3)' : ''
        "
        icon="mdi-motion-play"
        v-on:click="motionPlaySelector = !motionPlaySelector"
      />

      <IconButton
        class="gallery-menu ml-2"
        v-show="
          timelapse != null &&
          timelapse.downloadUrl != null &&
          !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)
        "
        :buttonColor="!playable ? 'grey lighten-4' : 'black'"
        :disabled="loadingImage"
        :loading="!loadingImage && !loaded && timelapse != null"
        :style="!playable ? 'background-color: rgba(0, 0, 0, .3)' : ''"
        icon="mdi-timelapse"
        v-on:click="timelapseReplay(dataHandle)"
      />

      <!-- DOWNLOAD IMAGE -->
      <IconButton
        v-show="!fullscreen"
        class="gallery-menu ml-2"
        color="black"
        buttonColor="black"
        icon="mdi-link-variant"
        v-on:click="$emit('share', dataHandle)"
      />

      <IconButton
        v-show="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)"
        class="gallery-menu ml-2"
        v-on:click="$emit('fullscreen', index)"
        color="black"
        buttonColor="black"
        :icon="!fullscreen ? 'mdi-fullscreen' : 'mdi-fullscreen-exit'"
      />
    </v-row>

    <div
      v-if="
        !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) &&
        !loadingImage &&
        timelapse != null &&
        timelapse.downloadUrl != null &&
        !metaPressed &&
        ((((hover && autoplay) || !autoplay) && !fullscreen && playable) ||
          (playable && fullscreen))
      "
    >
      <video
        v-show="
          !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) &&
          timelapse != null &&
          timelapse.downloadUrl != null &&
          !metaPressed &&
          ((((hover && autoplay) || !autoplay) && !fullscreen && playable) ||
            (playable && fullscreen))
        "
        class="video-player"
        :style="!loaded ? 'opacity: 0; cursor: progress' : ''"
        :autoplay="!motionPlaySelector"
        muted
        :loop="!motionPlaySelector"
        preload
        :ref="`video`"
        v-on:canplaythrough="loadedVideoData"
        v-on:timeupdate="timeUpdateEvent"
        v-on:loadstart="loaded = false"
      >
        <source
          :src="timelapse.downloadUrl"
          type="video/mp4"
          codecs="avc1.4d002a, mp4a.40.2"
        />
      </video>
    </div>

    <v-overlay
      color="white"
      :value="loadingImage"
      style="z-index: 10"
      :absolute="!fullscreen"
    >
      <v-progress-circular
        indeterminate
        width="3"
        size="30"
        color="primary"
      ></v-progress-circular>
    </v-overlay>

    <ImageZoom
      v-show="
        fullscreen && dataHandle != null && dataHandle.image.downloadUrl != null
      "
      :style="
        (metaPressed && !fullscreen) || !playable || timelapse == null
          ? ''
          : 'cursor: default!important;'
      "
      :disabled="false"
      class="gallery-image"
      :zoomScale="1.5"
      :hideHint="true"
      :src="dataHandle.image.downloadUrl || ''"
      style="height: 100%; width: 100%"
      v-on:load="loadedImageEvent"
    />

    <img
      style="width: 100%; height: 100%"
      v-show="
        !fullscreen &&
        dataHandle != null &&
        dataHandle.image.downloadUrl != null
      "
      :src="dataHandle.image.downloadUrl || ''"
      v-on:load="loadedImageEvent"
    />

    <div
      v-show="dataHandle == null || dataHandle.image.downloadUrl == null"
      class="image-error"
    >
      <v-row class="ma-0" align="center">
        <v-icon color="black" size="15" class="mr-1">mdi-alert-circle</v-icon>
        <b>{{ $t('tools.project.dashboard.error.image') }}</b>
      </v-row>
    </div>

    <div
      v-show="
        !(metaPressed && hover) &&
        dataHandle.presetId != null &&
        dataHandle.presetId != null &&
        !motionPlaySelector
      "
      class="gallery-alt-preset font-weight-bold black--text"
    >
      <v-row class="ma-0" align="center">
        <span
          class="font-weight-medium mr-1"
          style="
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            padding-bottom: 2px;
          "
          v-show="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)"
          >{{ $t('tools.project.dashboard.preset') }}:</span
        >

        <span class="font-weight-normal">{{
          $helper.shortenText($helper.formatPresetName(dataHandle.presetId), 16)
        }}</span>
      </v-row>
    </div>

    <div
      v-show="
        !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) &&
        !(metaPressed && hover) &&
        dataHandle.presetId != null &&
        dataHandle.presetId != null &&
        hover
      "
      class="gallery-alt-date font-weight-bold black--text"
    >
      <v-row class="ma-0" align="center">
        <span style="font-size: 15px" class="font-weight-normal">{{
          $helper.formatDate(dataHandle.imageTime)
        }}</span>
      </v-row>
    </div>

    <div
      v-if="$refs.video != null"
      v-show="
        playable &&
        loaded &&
        ((hover && autoplay) || playable) &&
        (hover || fullscreen) &&
        !metaPressed &&
        dataHandle != null
      "
      style="position: absolute; top: 10px; left: 10px; z-index: 10"
      class="white--text"
    >
      <span :key="currentVideoFrame">
        <div
          style="
            background-color: rgba(255, 255, 255, 0.8);
            width: 32px;
            height: 32px;
            border-radius: 19px;
          "
          class="pa-1"
        >
          <v-progress-circular
            rotate="-90"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
            width="6"
            size="21"
            color="primary"
            :value="(currentVideoFrame / maxVideoFrame) * 100"
          >
          </v-progress-circular>
        </div>
      </span>
    </div>

    <div
      v-show="
        fullscreen &&
        motionPlaySelector &&
        playable &&
        $refs.video != null &&
        $refs.video.duration != null &&
        $refs.video.duration != 0
      "
      style="
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 1000;
        width: 80%;
        max-width: 420px;
        border-radius: 10px;
      "
    >
      <v-row class="ma-0 pl-4 pr-4 pt-1 pb-1" align="center">
        <span style="font-size: 16px">
          <b class="mr-2">{{
            $t('tools.project.dashboard.fullscreen.timelapse')
          }}</b>
          <span style="font-size: 15px">{{
            $t('tools.project.dashboard.fullscreen.frameByFrame')
          }}</span>
        </span>
        <v-spacer />
        <span
          style="font-size: 14px; color: rgba(0, 0, 0, 0.5)"
          v-if="$refs.video != null"
        >
          <span class="mr-1">{{ videoMotionPosition / 10 }}</span> /
          <span class="ml-1">{{
            Math.ceil($refs.video.duration / (1 / 30))
          }}</span>
        </span>
        <!-- <v-spacer/> -->
        <IconButton
          class="ml-2"
          :style="
            sliderSelector
              ? 'transform: rotate(-180deg); transition: all 200ms linear'
              : 'transition: all 200ms linear'
          "
          v-on:click="sliderSelector = !sliderSelector"
          icon="mdi-chevron-down"
        />
      </v-row>
      <v-slider
        v-show="sliderSelector"
        dense
        hide-details
        class="pt-1 pl-6 pr-6 pb-4"
        v-if="$refs.video != null && $refs.video.duration != null"
        v-model="videoMotionPosition"
        track-color="grey darken-2"
        color="primary"
        :thumb-size="2"
        :step="10"
        :min="10"
        :max="Math.ceil($refs.video.duration / (1 / 30)) * 10"
      ></v-slider>
    </div>
    <v-fade-transition>
      <div
        v-show="lastFrameBlink"
        style="
          height: 100vh;
          width: 100vw;
          background-color: transparent;
          z-index: 10000;
          position: absolute;
        "
      >
        <div
          style="
            position: absolute;
            top: 47%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60px;
            height: 60px;
            background-color: white;
            opacity: 0.5;
            border-radius: 50%;
          "
        >
          <v-icon
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
            size="36"
            color="black"
            >mdi-close-circle-outline</v-icon
          >
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import IconButton from '@components/button/IconButton.vue';
// import InnerImageZoom from 'vue-inner-image-zoom';
import ImageZoom from '@components/image/ImageZoom.vue';
import PlaybackSpeed from '@components/video/PlaybackSpeed.vue';

export default {
  name: 'LiveViewCard',
  props: {
    autoplay: {
      type: Boolean,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    dataHandle: {
      required: true,
    },
    timelapse: {
      required: true,
    },
    trigger: {
      default: false,
    },
    index: {
      default: 0,
    },
    hover: {
      default: false,
    },
    loading: {
      default: false,
    },
    playbackSpeed: {
      type: Number,
      default: 0.7,
    },
    reset: {
      default: false,
    },
  },
  components: {
    IconButton,
    // InnerImageZoom,
    ImageZoom,
    PlaybackSpeed,
  },

  data() {
    return {
      currentVideoFrame: 0,
      maxVideoFrame: 0,
      playbackSpeedLocal: this.playbackSpeed,
      metaPressed: false,
      loaded: false,
      playable: true,
      // dataHandle: this.data,
      updatedData: false,
      motionPlaySelector: false,
      motionPlayStatus: true,
      lastUpdateTime: 0,
      videoMotionPosition: 10,
      sliderSelector: false,
      lastFrameBlink: false,
      loadingImage: true,
    };
  },

  created() {
    this.loadingImage = true;

    if (this.fullscreen || !this.autoplay) this.playable = false;

    if ((this.fullscreen || !this.autoplay) && !this.playable)
      this.loaded = true;
    // Event Listeners

    this.motionPlaySelector = false;

    document.addEventListener('keyup', this.keyboardEvent, false);
    // document.addEventListener('keydown', this.keyboardDownEvent, false);
  },

  watch: {
    hover() {
      if (this.hover == true && this.$refs.video != null)
        this.$refs.video.play();
    },
    reset() {
      this.motionPlaySelector = false;
      this.playable = false;
      this.loadingImage = true;

      if (this.$refs.video == null) return;
      this.$refs.video.playbackRate = this.playbackSpeed;
    },
    videoMotionPosition: {
      handler() {
        if (
          this.$refs.video == null ||
          this.$refs.video.currentTime == null ||
          this.videoMotionPosition == null ||
          isNaN(this.videoMotionPosition) ||
          this.motionPlaySelector != true
        )
          return;

        this.$refs.video.currentTime =
          (this.videoMotionPosition / 10) * (1 / 30);
      },
    },
    motionPlaySelector: {
      handler() {
        if (this.$refs.video == null) return;

        try {
          var isPlaying =
            this.$refs.video.currentTime > 0 &&
            !this.$refs.video.paused &&
            !this.$refs.video.ended &&
            this.$refs.video.readyState > this.$refs.video.HAVE_CURRENT_DATA;
          if (this.motionPlaySelector == true) {
            this.$refs.video.currentTime = 0;
            this.$refs.video.pause();
            this.sliderSelector = true;
          } else if (!isPlaying) {
            this.$refs.video.play();
          }
        } catch (error) {
          console.log(error);
        }

        return this.$emit('motion', this.motionPlaySelector);
      },
      immediate: true,
    },
    autoplay: {
      handler() {
        if (this.autoplay == false) {
          this.playable = false;
          this.loaded = true;
        } else {
          this.playable = true;
          this.loaded = false;
        }
      },
      immediate: true,
      deep: true,
    },
    playable: {
      handler() {
        if (this.timelapse == null) return;

        if (this.playable == true) {
          this.motionPlaySelector = false;
        }

        if (this.$refs.video == null) return;
        return this.$refs.video.play();
      },
      immediate: true,
    },
    playbackSpeed: {
      handler() {
        this.playbackSpeedLocal = this.playbackSpeed;
        if (this.$refs.video == null) return;
        this.$refs.video.playbackRate = this.playbackSpeed;

        this.$refs.video.play();
      },
      immediate: true,
      deep: true,
    },

    playbackSpeedLocal: {
      handler() {
        if (this.$refs.video == null || !this.fullscreen) return;
        this.$refs.video.playbackRate =
          this.playbackSpeedLocal || this.playbackSpeed;

        this.$refs.video.play();
      },
    },

    trigger: {
      handler() {
        if (this.fullscreen == true) this.playable = false;
        if (this.fullscreen == true && this.playable == false)
          this.loaded = true;
      },
    },

    data: {
      handler() {
        this.loadingImage = true;
        if (this.data == null) return;
        // eslint-disable-next-line
        if (this.dataHandle == null) return (this.dataHandle = this.data);

        if (this.data.captureTime == this.dataHandle.captureTime) return;

        this.updatedData = !this.updatedData;
        setTimeout(() => {
          this.refreshImpulse = false;
        }, 1000);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    loadedImageEvent() {
      this.loadingImage = false;
    },
    timeUpdateEvent() {
      if (this.$refs.video == null) return;

      this.currentVideoFrame = Math.ceil(
        this.$refs.video.currentTime / (1 / 30),
      );

      if (this.motionPlaySelector != true) return;
      this.lastUpdateTime = this.$refs.video.currentTime;
      this.videoMotionPosition =
        Math.ceil(this.$refs.video.currentTime / (1 / 30)) * 10;
    },

    async timelapseReplay() {
      if ((this.fullscreen || !this.autoplay) && !this.playable)
        this.loaded = false;
      this.playable = !this.playable;
    },

    // keyboardDownEvent(eventHandle) {
    //     if (this.timelapse != null && eventHandle.keyCode == 91) this.metaPressed = true; // set meta key handle
    // },

    keyboardEvent(eventHandle) {
      // if (this.timelapse != null && eventHandle.keyCode == 13 && (this.hover || this.fullscreen)) this.playable = !this.playable
      // if (this.metaPressed && eventHandle.keyCode == 91) this.metaPressed = false; // reset meta key handle

      if (this.motionPlaySelector == true) {
        if (this.$refs.video == null) return;

        switch (eventHandle.keyCode) {
          case 38:
            if (
              this.videoMotionPosition >=
              Math.ceil(this.$refs.video.duration / (1 / 30)) * 10
            ) {
              this.lastFrameBlink = true;
              setTimeout(() => {
                this.lastFrameBlink = false;
              }, 300);
              return;
            } else {
              this.lastFrameBlink = false;
            }

            this.videoMotionPosition += 10;
            break;

          case 40:
            if (this.videoMotionPosition <= 10) {
              this.lastFrameBlink = true;
              setTimeout(() => {
                this.lastFrameBlink = false;
              }, 300);
              return;
            } else {
              this.lastFrameBlink = false;
            }
            this.videoMotionPosition -= 10;
            break;

          default:
            break;
        }
      }
    },

    loadedVideoData() {
      this.loaded = true;

      if (this.motionPlaySelector || this.$refs.video == null) return;

      this.maxVideoFrame = Math.ceil(this.$refs.video.duration / (1 / 30));
      this.$refs.video.playbackRate =
        this.playbackSpeedLocal || this.playbackSpeed;
      this.$refs.video.play();
    },
  },
};
</script>

<style scoped>
.gallery-image-container {
  position: absolute;
  border-radius: 5px !important;
  min-height: 100%;
  max-height: 100%;

  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  transition: all 100ms linear;
  background-color: rgba(220, 220, 220, 1);
}

.gallery-image {
  position: absolute;
  height: 100%;
  display: block;
  border-radius: 5px !important;
  z-index: 9;
}

.gallery-alt-preset {
  position: absolute;
  bottom: 8px;
  left: 10px;
  z-index: 20;
  background-color: rgba(220, 220, 220, 0.9);
  border-radius: 12px;
  height: 27px;
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 12px;
}

.gallery-alt-date {
  position: absolute;
  bottom: 8px;
  right: 10px;
  z-index: 20;
  background-color: rgba(220, 220, 220, 0.9);
  border-radius: 12px;
  height: 27px;
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 12px;
}

.action-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}

.public-icon {
  position: absolute;
  top: 10px;
  left: 10px;

  z-index: 100;
}

.preset-bar-image {
  position: relative;
  background-color: transparent;
  min-height: 40px;
  width: 71px;
  border-radius: 5px;
  display: inline-block;
}

.gallery-menu {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  z-index: 100;
}

.video-player {
  position: absolute;
  width: 100%;
  z-index: 10 !important;
}

.image-error {
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-container {
  position: relative;
  height: 22px;
  cursor: pointer;
  color: rgba(220, 220, 220, 1);
  width: 60px;
  background-color: rgba(150, 150, 150, 1);
  border-radius: 13px;
}

.button-container-text {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playbackspeed-overlay {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 250;
  background-color: rgba(250, 250, 250, 0.8);
  padding: 5px;
  border-radius: 15px;
}
</style>
