var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fullscreen',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly)),expression:"!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)"}],staticClass:"black",staticStyle:{"background-color":"black"},model:{value:(_vm.fullscreen),callback:function ($$v) {_vm.fullscreen=$$v},expression:"fullscreen"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fullscreen),expression:"fullscreen"}],staticClass:"fullscreen-container; overflow: hidden!important; height: 100vh; width: 100vw; background-color: black",attrs:{"oncontextmenu":"return false;"}},[_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.isArchived))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly) &&
            _vm.fullscreen
          ),expression:"\n            !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) &&\n            fullscreen\n          "}],staticStyle:{"position":"relative","height":"calc(100vw * (9 / 16))","margin-top":"calc(50vh - ((100vw * (9 / 16)) / 2))","width":"100vw","overflow":"hidden"}},_vm._l(((_vm.isArchived == true ? _vm.archivedPresets : _vm.activePresets) || []),function(item,index){return _c('LiveViewCard',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.presetIndex),expression:"index == presetIndex"}],key:index + _vm.fullscreen,attrs:{"reset":_vm.fullscreen,"autoplay":false,"trigger":_vm.fullscreen,"fullscreen":true,"index":0,"dataHandle":item,"hover":false,"timelapse":item.timelapse},on:{"motion":function($event){_vm.motionPlayHandle = $event},"fullscreen":_vm.toggleFullscreen}})}),1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.latest && _vm.latest.length > 1),expression:"latest && latest.length > 1"}],staticStyle:{"z-index":"20 !important"},attrs:{"justify":"center"}},[_c('div',{staticClass:"preset-bar pb-2"},[_c('div',{staticClass:"grow"},_vm._l((((_vm.isArchived == true ? _vm.archivedPresets : _vm.activePresets) || [])),function(view,index){return _c('div',{key:index,staticClass:"ma-1 preset-bar-image",style:(index == _vm.presetIndex ? 'cursor: default' : 'cursor: pointer'),on:{"click":function($event){_vm.presetIndex = index}}},[_c('v-img',{staticStyle:{"background-color":"rgba(220, 220, 220, 1)","border-radius":"5px"},attrs:{"height":"40px","preload":"","contain":"","src":view.thumbnail.downloadUrl}},[_c('v-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.presetIndex == index),expression:"presetIndex == index"}],attrs:{"absolute":"","opacity":0.4,"color":"primary"}})],1),_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"0px","background-color":"transparent","min-height":"40px","width":"72px","border-radius":"5px"},style:(index == _vm.presetIndex
                      ? 'border: 2px solid var(--v-primary-base);'
                      : '')})],1)}),0)])])],1)]),_c('Gallery',{staticClass:"pt-2 pb-2",attrs:{"data":_vm.activePresets,"id":"gallery-latest","noOverlay":true,"dense":_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly},scopedSlots:_vm._u([{key:"content",fn:function(ref){
                      var data = ref.data;
                      var hover = ref.hover;
                      var index = ref.index;
return [(data != null && data.presetId != null)?_c('LiveViewCard',{attrs:{"autoplay":_vm.autoplay,"playbackSpeed":_vm.playbackSpeed,"fullscreen":false,"index":index,"dataHandle":data,"hover":hover,"timelapse":data.timelapse},on:{"share":_vm.shareFile,"fullscreen":_vm.toggleFullscreen}}):_vm._e()]}}])}),(_vm.archivedPresets != null && _vm.archivedPresets.length > 0)?_c('div',{staticClass:"ml-2 mr-2",staticStyle:{"border":"2px solid #F0f0f0","border-radius":"5px","background":"#FAFAFA"}},[_c('div',{staticClass:" ma-3 font-weight-bold row ma-0 align-center",staticStyle:{"font-size":"17px","cursor":"pointer"},on:{"click":function($event){_vm.showInactive = !_vm.showInactive}}},[_vm._v(" "+_vm._s(_vm.archivedPresets.length)+" "+_vm._s(_vm.$t('tools.project.dashboard.inactive'))+" "),_c('v-spacer'),_c('IconButton',{staticClass:"mr-2",attrs:{"buttonColor":"primary","icon":_vm.showInactive ? 'mdi-chevron-up' : 'mdi-chevron-down'},on:{"click":function($event){$event.stopPropagation();_vm.showInactive = !_vm.showInactive}}})],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInactive),expression:"showInactive"}]},[(_vm.archivedPresets != null && _vm.archivedPresets.length > 0 )?_c('Gallery',{staticClass:"pt-2 pb-2",attrs:{"data":_vm.archivedPresets,"id":"gallery-latest","noOverlay":true,"dense":_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly},scopedSlots:_vm._u([{key:"content",fn:function(ref){
                      var data = ref.data;
                      var hover = ref.hover;
                      var index = ref.index;
return [(data != null && data.presetId != null)?_c('LiveViewCard',{staticStyle:{"opacity":".75"},attrs:{"autoplay":_vm.autoplay,"playbackSpeed":_vm.playbackSpeed,"fullscreen":false,"index":index,"dataHandle":data,"hover":hover,"timelapse":data.timelapse},on:{"share":_vm.shareFile,"fullscreen":function($event){return _vm.toggleFullscreen($event, true)}}}):_vm._e()]}}],null,false,752546242)}):_vm._e()],1)])],1):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showImageShareDialog),callback:function ($$v) {_vm.showImageShareDialog=$$v},expression:"showImageShareDialog"}},[_c('ShareDialog',{attrs:{"shareObj":_vm.shareUrl.preset,"url":_vm.shareUrl.url},on:{"close":function($event){_vm.showImageShareDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }