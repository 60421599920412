<template>
  <div>
    <fullscreen
      v-show="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)"
      v-model="fullscreen"
      style="background-color: black"
      class="black"
    >
      <div
        oncontextmenu="return false;"
        v-show="fullscreen"
        class="fullscreen-container; overflow: hidden!important; height: 100vh; width: 100vw; background-color: black"
      >
    
     <span class="red--text"> {{ isArchived }}</span>
        <div
          v-show="
            !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) &&
            fullscreen
          "
          style="
            position: relative;
            height: calc(100vw * (9 / 16));
            margin-top: calc(50vh - ((100vw * (9 / 16)) / 2));
            width: 100vw;
            overflow: hidden;
          "
        >

 
          <LiveViewCard
            :reset="fullscreen"
            v-on:motion="motionPlayHandle = $event"
            :autoplay="false"
            :trigger="fullscreen"
            v-for="(item, index) in (isArchived == true ? archivedPresets : activePresets) || []"
            :key="index + fullscreen"
            v-show="index == presetIndex"
            v-on:fullscreen="toggleFullscreen"
            :fullscreen="true"
            :index="0"
            :dataHandle="item"
            :hover="false"
            :timelapse="item.timelapse"
          />
         
        </div>
        <v-row
          v-show="latest && latest.length > 1"
          justify="center"
          style="z-index: 20 !important"
        >
          <div class="preset-bar pb-2">
            <!-- CONTENT PRESET BAR -->
            <div class="grow">
              <div
                class="ma-1 preset-bar-image"
                v-for="(view, index) in ((isArchived == true ? archivedPresets : activePresets) || [])"
                :key="index"
                v-on:click="presetIndex = index"
                :style="
                  index == presetIndex ? 'cursor: default' : 'cursor: pointer'
                "
              >
                <v-img
                  height="40px"
                  preload
                  contain
                  :src="view.thumbnail.downloadUrl"
                  style="
                    background-color: rgba(220, 220, 220, 1);
                    border-radius: 5px;
                  "
                >
                  <v-overlay
                    absolute
                    v-show="presetIndex == index"
                    :opacity="0.4"
                    color="primary"
                  />
                </v-img>

                <div
                  :style="
                    index == presetIndex
                      ? 'border: 2px solid var(--v-primary-base);'
                      : ''
                  "
                  style="
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background-color: transparent;
                    min-height: 40px;
                    width: 72px;
                    border-radius: 5px;
                  "
                />
              </div>
            </div>
          </div>
        </v-row>
      </div>
    </fullscreen>
 
<!-- {{ archivedPresets }} -->
    <Gallery
      :data="activePresets"
      id="gallery-latest"
      :noOverlay="true"
      class="pt-2 pb-2"
      :dense="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly"
    >
      <template v-slot:content="{data, hover, index}">
        <LiveViewCard
          :autoplay="autoplay"
          v-on:share="shareFile"
          :playbackSpeed="playbackSpeed"
          v-if="data != null && data.presetId != null"
          v-on:fullscreen="toggleFullscreen"
          :fullscreen="false"
          :index="index"
          :dataHandle="data"
          :hover="hover"
          :timelapse="data.timelapse"
        />
      </template>
    </Gallery>


    <!-- <v-divider v-if="archivedPresets != null && archivedPresets.length > 0" class="ma-2 mb-3"/> -->

        <div v-if="archivedPresets != null && archivedPresets.length > 0" class="ml-2 mr-2"  style="border: 2px solid #F0f0f0; border-radius: 5px; background: #FAFAFA;">
    <div v-on:click="showInactive = !showInactive" style="font-size: 17px; cursor: pointer;" class=" ma-3 font-weight-bold row ma-0 align-center">
        
        {{archivedPresets.length}} {{$t('tools.project.dashboard.inactive')}}
        
        <v-spacer/>
        <IconButton class="mr-2" v-on:click.stop="showInactive = !showInactive" buttonColor="primary" :icon=" showInactive ? 'mdi-chevron-up' : 'mdi-chevron-down'"/>
    
    </div>

    
    <v-expand-transition>
        <div v-show="showInactive">
    <Gallery

    v-if="archivedPresets != null && archivedPresets.length > 0 "
      :data="archivedPresets"
      id="gallery-latest"
      :noOverlay="true"
      class="pt-2 pb-2"
      :dense="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly"
    >
      <template v-slot:content="{data, hover, index}">
        <LiveViewCard
        style="opacity: .75"
          :autoplay="autoplay"
          v-on:share="shareFile"
          :playbackSpeed="playbackSpeed"
          v-if="data != null && data.presetId != null"
          v-on:fullscreen="toggleFullscreen($event, true)"
          :fullscreen="false"
          :index="index"
          :dataHandle="data"
          :hover="hover"
          :timelapse="data.timelapse"
        />
      </template>
    </Gallery>
    </div>
    </v-expand-transition>
        </div>
    <v-dialog v-model="showImageShareDialog" width="500">
      <ShareDialog
        v-on:close="showImageShareDialog = false"
        :shareObj="shareUrl.preset"
        :url="shareUrl.url"
      />
    </v-dialog>
  </div>
</template>

<script>
import Gallery from '@components/list/Gallery.vue';
import LiveViewCard from './LiveViewCard.vue';
import ShareDialog from '@/components/tools/project/helper/ShareDialog.vue';
import IconButton from '@components/button/IconButton.vue';

export default {
  name: 'LiveViewGallery',
  props: {
    latest: {
      required: true,
    },
    playbackSpeed: {
      type: Number,
      default: 0.7,
    },
    autoplay: {
      default: true,
    },
  },
  components: {
    Gallery,
    LiveViewCard,
    ShareDialog,
    IconButton,
  },

  data() {
    return {
        showInactive: false,
      motionPlayHandle: false,
      shareUrl: {preset: null, url: null},
      showImageShareDialog: false,
      metaPressed: false,
      loadingDownload: null,
      showPresetVideo: null,
      presetTimelapse: null,
      presetVideoLoaded: false,
      fullscreen: false,
      presetIndex: 0,
      isArchived: false,
      timelapses: null,
      timelapsesLoading: false,
    };
  },

  created() {
    // Event Listeners
    document.addEventListener('keyup', this.keyboardEvent, false);
    document.addEventListener('keydown', this.keyboardDownEvent, false);

    if (this.latest == null) return;
    // this.getTimelapseData();
  },


  computed: {
    archivedPresets() {
        return this.latest.filter(image => (Date.now() - new Date(image.imageTime)) / (1000 * 60 * 60 * 24) > 7);
    },

    activePresets() {
        return this.latest.filter(image => (Date.now() - new Date(image.imageTime)) / (1000 * 60 * 60 * 24) < 7);
    }
  },
  methods: {
    shareFile(handle) {
      this.shareUrl.url = handle.image.downloadUrl;
      this.shareUrl.preset = {
        isLatestImagePublic: handle.isLatestImagePublic,
        shareType: 'image',
        name: this.$helper.formatDate(handle.imageTime),
        shareUrl: `link=${encodeURIComponent(
          handle.image.downloadUrl,
        )}&expiresAt=${new Date(handle.image.expiresAt).toISOString()}`,
        presetId: handle.presetId,
        date: handle.imageTime,
        type: 0,
        deviceId: handle.cameraId,
        timelapseUrl: handle.timelapse.downloadUrl,
        imageUrl: handle.image.downloadUrl,
        thumbnailUrl: handle.thumbnail.downloadUrl,
      };
      this.showImageShareDialog = !this.showImageShareDialog;
      console.log(this.shareUrl);
    },
    // async getTimelapseData() {

    //     this.timelapsesLoading = true;
    //     const resp = await Promise.all(this.latest.map(presetHandle => this.getTimelapseReplay(presetHandle)));

    //     this.timelapsesLoading = false;
    //     if (resp == null) return;

    //     this.timelapses = resp;

    // //     this.$nextTick(() => {
    // //         this.latest.forEach(preset => {

    // //         if (preset == null) return;
    // //         var doc = document.getElementById(`timelapse-player-${preset.presetId}`)

    // //         if (doc != null) {
    // //             // console.log("HERERE")
    // //             doc.playbackRate = 0.5
    // //             // console.log(doc.playbackRate)
    // //         }
    // //     });
    // // })

    // },

    // async getTimelapseReplay(presetHandle) {
    //     try {
    //         const videoResp = await this.$api.project.timelapseReplay(presetHandle.projectId, presetHandle.cameraId, presetHandle.presetId);

    //         if (videoResp.success == true && videoResp.data != null) {
    //             return {play: true, loaded: false, ...videoResp.data}
    //         } else {
    //             return;
    //         }

    //     } catch {
    //         return;
    //     }
    // },

   
    toggleFullscreen(indexHandle, archived = false) {

        this.isArchived = archived
      this.presetIndex = indexHandle;
      this.fullscreen = !this.fullscreen;
    },

    keyboardDownEvent(eventHandle) {
      if (eventHandle.keyCode == 91) this.metaPressed = true; // set meta key handle
    },

    keyboardEvent(eventHandle) {
      if (this.metaPressed && eventHandle.keyCode == 91)
        this.metaPressed = false; // reset meta key handle

      if (this.fullscreen == false) return;
      switch (eventHandle.keyCode) {
        case 39:
          if (this.presetIndex == this.latest.length - 1) this.presetIndex = 0;
          else this.presetIndex += 1;
          break;
        case 37:
          if (this.presetIndex == 0) this.presetIndex = this.latest.length - 1;
          else this.presetIndex -= 1;
          break;
      }
    },

    endVideoEvent() {},

    triggerAutoPlay(presetId) {
      const presetIndex = this.timelapses.findIndex(
        (preset) => preset.presetId == presetId,
      );
      if (presetIndex != -1)
        this.timelapses[presetIndex].play = !this.timelapses[presetIndex].play;
    },

    loadedVideoData(presetId) {
      const presetIndex = this.timelapses.findIndex(
        (preset) => preset.presetId == presetId,
      );
      if (presetIndex != -1) this.timelapses[presetIndex].loaded = true;
    },
  },
};
</script>

<style scoped>
.grow {
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
  height: 51px;
  width: auto;
  /* margin: 5px 1% 5px 1%; */
  z-index: 15000 !important;

  transform-origin: center bottom;
  transition: transform 0.25s;
  -webkit-transition: transform 0.25s;
  text-align: center;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.grow ::-webkit-scrollbar {
  width: 0 !important;
}

.grow::-webkit-scrollbar {
  display: none !important;
}

.grow {
  overflow: -moz-scrollbars-none;
}

.grow {
  -ms-overflow-style: none !important;
}

.fullscreen-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.preset-bar {
  background-color: rgba(220, 220, 220, 0.9);
  position: absolute;
  bottom: 10px;

  z-index: 100000;
  max-width: 95%;
  border-radius: 10px;

  transition: all 100ms linear;
}

.preset-bar-image {
  position: relative;
  background-color: transparent;
  min-height: 40px;
  width: 71px;
  border-radius: 5px;
  display: inline-block;
}
</style>
